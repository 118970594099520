import React from 'react'
import AdminLoginPage from '../components/admin/admin-login'

export default function AdminLogin() {
    return (
        <div className='admin'>
            <AdminLoginPage />
        </div>
    )
}
