import AdminNavBar from '../components/admin/nav/nav'
import Home from '../components/admin/home/home'
export default function AdminHome() {
    return (
        <>
            <AdminNavBar />
            <Home />
        </>

    )
}
